import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["checkbox", "dateField", "submitButton"];

  connect() {
    this.initializeDatePickers();
    this.handlePreselectedCourses();
    const closeButton = document.getElementById("course-schedule-close");
    if (closeButton) {
      closeButton.addEventListener("click", this.reloadForm.bind(this));
    }
  }

  // Handle pre-selection on page load
  handlePreselectedCourses() {
    this.checkboxTargets.forEach((checkbox) => {
      const dateField = this.dateFieldTargets.find(
        (field) => field.id === `assessment_date_${checkbox.value}`
      );
      if (dateField) {
        dateField.disabled = !checkbox.checked;
      }
    });
  }

  // Toggle date fields based on checkbox selection
  toggleSelection(event) {
    const checkbox = event.target;
    const dateField = this.dateFieldTargets.find(
      (field) => field.id === `assessment_date_${checkbox.value}`
    );

    if (dateField) {
      dateField.disabled = !checkbox.checked;
      if (!dateField.disabled) {
        dateField.focus();
      } else {
        dateField.value = ""; // Clear date if checkbox is unchecked
      }
    }
  }

  initializeDatePickers() {
    this.dateFieldTargets.forEach((dateField) => {
      $(dateField)
        .datepicker({
          numberOfMonths: 1,
          dateFormat: "M d, yy",
          minDate: new Date(),
          onSelect: function (date) {
            $(this).val(date);
          }
        })
        .on("keypress", function (e) {
          e.preventDefault();
        });
    });
  }

  // Confirm submission with a summary of new selections
  confirmSubmission(event) {
    event.preventDefault();

    // Collect newly selected courses with their dates
    const newCoursesWithDates = this.checkboxTargets
      .filter(
        (checkbox) => checkbox.checked && checkbox.dataset.isNew === "true"
      )
      .map((checkbox) => {
        const dateField = document.getElementById(
          `assessment_date_${checkbox.value}`
        );
        return {
          name: checkbox.value.split("_") .map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" "),
          date: dateField && dateField.value ? dateField.value : "No date selected"
        };
      });

    // If there are new selections, show confirmation
    if (newCoursesWithDates.length > 0) {
      const courseDetails = newCoursesWithDates
        .map((course) => `${course.name} starting on ${course.date}`)
        .join("\n");

      const confirmation = confirm(
        `Saving this work will allow faculty users to begin scheduling Calibrate assessments for the following:\n\n${courseDetails}`
      );

      if (!confirmation) return;
    }

    this.element.submit();
  }

  reloadForm() {
    const form = document.getElementById("course-schedule-form");
    if (form) {
      form.reset();
    }
    // Reinitialize the date pickers and pre-selections
    this.initializeDatePickers();
    this.handlePreselectedCourses();
  }
}
