import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="form"
export default class extends Controller {
  static targets = [ "sort-columns" ]

  showLoader() {
    const loader = document.getElementById('content-summary-loader');
    if (loader) {
      loader.style.display = 'block';
    }
    const loaderMobile = document.getElementById('content-summary-loader-mobile');
    if (loaderMobile) {
      loaderMobile.style.display = 'block';
    }
  }
}
