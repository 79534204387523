import { Controller } from "@hotwired/stimulus";
import React from "react";
import { createRoot } from "react-dom/client";
import AssessmentDashboardModal from "../src/react/components/Assessment/Dashboard/assessment_dashboard_modal";

export default class extends Controller {
  static targets = ["modalContainer"];
  
  connect() {
    this.root = null;
  }

  showModal(event) {
    if (!event.target.closest('.modal-body')) {
      const modalContainer = this.modalContainerTarget;
      modalContainer.classList.remove("hidden");

      const props = event.currentTarget.dataset.props
        ? JSON.parse(event.currentTarget.dataset.props)
        : {};

      if (!this.root) {
        this.root = createRoot(modalContainer);
      }

      this.root.render(
        <AssessmentDashboardModal
          {...props}
          isOpen={true}
          onClose={() => this.closeModal()}
          onSave={(options) => console.log("Save options:", options)}
        />
      );
    }
  }

  closeModal() {
    this.unmountRoot();
    this.modalContainerTarget.classList.add("hidden");
  }

  disconnect() {
    this.unmountRoot();
  }

  unmountRoot() {
    if (this.root) {
      this.root.unmount();
      this.root = null;
    }
  }
}
