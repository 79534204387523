import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["restartModal", "grdu", "reason", "pauseModal", "examName",
                    "studentName", "pauseDate", "pauseTime", "pauseReason",
                    "startDate","endDate","academicStartDate","academicEndDate",
                    "restartCommit", "examCourse","dropdown", "hiddenField", "placeholder",
                    "aqueductTableRow", "defaultExamForm", "highlightedRowsInfo",
                    "highlightedRowsAlert", "warningModal", "courseSchedules"]

  initialize() {
    const courseSchedulingFeatureFlag = this.element.dataset.schedulingFormFlag === "true";
    if (this.hasExamCourseTarget) {
      this.setSelectedExamCourse();
    }

    // Setup Edit Start DatePicker
    $(this.startDateTarget).datepicker({
      numberOfMonths: 2,
      dateFormat: "mm-dd-yy",
      minDate: 0,
      onSelect: (selected) => {
        var availability_ends_at = new Date(selected);
        availability_ends_at.setDate(availability_ends_at.getDate() + 10);
        $(this.endDateTarget).datepicker("option", "minDate", selected);
        $(this.endDateTarget).datepicker("option", "maxDate", availability_ends_at);
        $("#date_changed").click();
        this.resetEndTimeAndDateFieldsEdit();
        if (courseSchedulingFeatureFlag) {
          this.checkDateInput(selected);
        } else if (this.hasExamCourseTarget) {
          this.reloadExamCourses();
        }
      }
    }).on("change", (event) => {
      let inputValue = event.target.value.trim();
      if (inputValue) {
        this.checkMinDate(inputValue);
        if (this.hasExamCourseTarget && !courseSchedulingFeatureFlag) this.reloadExamCourses();
      }
    });


    const startDateElem = document.getElementById("exam-start-date-picker-edit");
    const startMinDate  = startDateElem && new Date(startDateElem.value);
    const startMaxDate  = startDateElem && new Date(startDateElem.value);

    // Setup Edit End DatePicker
    $(this.endDateTarget).datepicker({
      numberOfMonths: 2,
      dateFormat: "mm-dd-yy",
      minDate: startMinDate,
      maxDate: startMaxDate && new Date(this.findMaxDate(startMaxDate)),
      onSelect: (selected) => {
        $("#date_changed").click();
        var start_date = document.getElementById("exam-start-date-picker-edit").value;
        var end_date = document.getElementById("exam-end-date-picker-edit").value;
        var needs_subset = start_date == end_date;
        this.rebuildEndTimeSelectOptionsEdit(needs_subset);
      }
    }).on('keypress', function(e){ e.preventDefault();});

  }

  connect() {
    document.addEventListener("click", this.closeDropdownOnClickOutside.bind(this));
    if (this.hasAcademicStartDateTarget) {
      this.formatDateInput(this.academicStartDateTarget);
    }
    if (this.hasAcademicEndDateTarget) {
      this.formatDateInput(this.academicEndDateTarget);
    }
    if (this.hasStartDateTarget){
      this.formatDateInput(this.startDateTarget);
    }
  }

  disconnect() {
    document.removeEventListener("click", this.closeDropdownOnClickOutside.bind(this));
  }

  delayedCheckHighlightedRows() {
    setTimeout(() => {
      this.checkHighlightedRows(); // Call the checkHighlightedRows function after a delay
    }, 100);
  }

  checkHighlightedRows() {
    let aqueductTable     = window.AqueductTable;
    let rows              = aqueductTable.state.rows;
    this.alertForHighlightedRow(rows);
    this.showHighlightedRowInfo(rows);
  }

  changeDefaultForm(ev) {
    const selectEl          = ev.target.closest('select');
    const default_exam_form = selectEl.value;
    const optionEl          = [...(selectEl.children)].find(elem => elem.value == default_exam_form);
    const default_form_name = optionEl.innerText;

    let aqueductTable     = window.AqueductTable;
    let rows              = aqueductTable.state.rows;

    rows.forEach(row => {
      let exam_form = row.exam_form;
      if (exam_form == 'default') {
        exam_form = default_exam_form;
      }
      row.default_form_name = default_form_name;
      row.row_class = row.forms_already_taken.includes(exam_form) ? "highlighted_rows" : "";
    });

    aqueductTable.setState({ rows });
    this.alertForHighlightedRow(rows);
    this.showHighlightedRowInfo(rows);
  }

  changeRosteringForm(ev) {
    let aqueductTable = window.AqueductTable;
    let rows          = aqueductTable.state.rows;
    let row           = rows.find(row => row.grdu_id == this.getGrduId(ev));

    const default_exam_form = this.defaultExamFormTarget.selectedOptions[0].value;
    let exam_form           = this.getExamForm(ev, default_exam_form);

    if(row.forms_already_taken.includes(exam_form)){
      row.row_class = "highlighted_rows";
      $(this.highlightedRowsInfoTarget).show();
    }
    else{
      row.row_class = "";
      $(this.highlightedRowsInfoTarget).hide();
    }
    row.exam_form = exam_form;

    aqueductTable.setState({ rows });
  }

  explainStatus(ev) {
    let grdu_id       = ev.target.closest('tr').querySelector('.grdu_id_cell input[type="hidden"]').value;
    let result
    $.ajax({
      url: "pause_details",
      type: "GET",
      async: false,
      dataType: "json",
      data: {grdu_id: grdu_id},
      success: function(response){
        result = response
      }
    });
    this.examNameTarget.textContent = result.exam_name
    this.studentNameTarget.textContent = result.student_name
    this.pauseDateTarget.textContent = result.pause_date
    this.pauseTimeTarget.textContent = result.pause_start_time+"-"+result.pause_end_time+" "+result.timezone
    this.pauseReasonTarget.textContent = result.pause_reason
    $(this.pauseModalTarget).show();
  }

  pauseClose(){
    $(this.pauseModalTarget).hide();
  }

  displayRestart(ev) {
    this.grduTarget.value = ev.target.closest('tr').querySelector('.grdu_id_cell input[type="hidden"]').value;

    // Transfer row-specific data held in the row's "Restart" button to the submit button element.
    this.restartCommitTarget.dataset.url    = ev.target.dataset.url;

    $(this.restartModalTarget).show();
  }

  closeRestart() {
    $(this.restartModalTarget).hide();
  }

  restartExam(){
    let grdu_id       = this.grduTarget.value;

    // The restart API endpoint is in the commit button's dataset.
    let url     = this.restartCommitTarget.dataset.url

    let aqueductTable = window.AqueductTable;
    let rows          = aqueductTable.state.rows;
    let rowIdx        = rows.findIndex(row => row.grdu_id == grdu_id);
    let assessment_rostering = {
      reason_for_restart: this.reasonTarget.value
    }
    $.ajax({
      url: url,
      type: "PATCH",
      async: false,
      dataType: "json",
      data: {assessment_rostering: assessment_rostering}
    });
    $(this.restartModalTarget).hide();
    rows[rowIdx].status = 'Restarted';
    rows[rowIdx].can_restart = false;
    aqueductTable.setState({ rows });
  }

  getGrduId(ev) {
    return ev.target.closest('tr').querySelector('.grdu_id_cell input[type="hidden"]').value;
  }

  getExamForm(ev, default_exam_form) {
    let exam_form = ev.target.closest('select').value;
    if (exam_form == 'default') {
      exam_form = default_exam_form;
    }
    return exam_form;
  }

  alertForHighlightedRow(rows){
    if (rows.some(row => row.row_class == "highlighted_rows" && row.selected)) {
      $(this.highlightedRowsAlertTarget).show();
    }
  }

  showHighlightedRowInfo(rows){
    if (rows.some(row => row.row_class == "highlighted_rows")){
      $(this.highlightedRowsInfoTarget).show();
    }
    else{
      $(this.highlightedRowsInfoTarget).hide();
    }
  }

  closehighlightedRowsAlert(){
    $(this.highlightedRowsAlertTarget).hide();
  }

  resetEndTimeAndDateFieldsEdit() {
    var end_date_picker = document.getElementById("exam-end-date-picker-edit");
    end_date_picker.value = null;

    var needs_subset = false;
    this.rebuildEndTimeSelectOptionsEdit(needs_subset);
  }

  rebuildEndTimeSelectOptionsEdit(needs_subset) {
    var start_time_select = document.getElementById("start-time-select");
    var end_time_select = document.getElementById("end-time-select");

    if (needs_subset) {
      var start_time = start_time_select.options[start_time_select.selectedIndex].text;

      // Extract subset of options after selected start_time
      var new_end_time_inner_html = start_time_select.innerHTML.slice(start_time_select.innerHTML.indexOf(start_time) - 1);
      end_time_select.innerHTML = new_end_time_inner_html;
    } else {
      // Reset time list to stock
      end_time_select.innerHTML = start_time_select.innerHTML;
    }
  }

  reloadExamCourses() {
    const url = this.examCourseTarget.dataset.url && new URL(this.examCourseTarget.dataset.url)
    const preserveAssessment = new URLSearchParams(window.location.search).get('preserve_assessment') === 'true';

    if (url) {
      if (preserveAssessment) {
        url.searchParams.append('start_date', this.examCourseTarget.dataset.startDate)
      } else {
        url.searchParams.append('start_date', this.startDateTarget.value)
      }
    }

    fetch(url)
      .then(response => response.text())
      .then(html => {
        const tempContainer = document.createElement('div');
        tempContainer.innerHTML = html.trim();

        // Find the new select element inside the fetched HTML
        const newSelect = tempContainer.querySelector('select');

        if (newSelect) {
          // Replace the current examCourseTarget with the new select element
          this.examCourseTarget.replaceWith(newSelect);
        }
      })
  }

  setSelectedExamCourse() {
    const selectedCourse = document.getElementById('hidden_exam_course')?.value;

    if (selectedCourse) {
      const selectedOption = Array.from(this.examCourseTarget.options).find(
        option => option.value === selectedCourse
      );

      if (selectedOption) {
        selectedOption.selected = true;
      }
    }
  }

  findMaxDate(min_date){
    return min_date.setDate(min_date.getDate()+10)
  }

  toggleDropdown() {
    if (this.hasDropdownTarget) {
      const isHidden = this.dropdownTarget.style.display === "none";
      this.dropdownTarget.style.display = isHidden ? "block" : "none";

      if (isHidden) {
        const firstCheckbox = this.dropdownTarget.querySelector('input[type="checkbox"]');
        if (firstCheckbox) firstCheckbox.focus();
      }
    }
  }

  handleDropdownKeydown(event) {
    if (event.key === " " || event.key === "Enter") {
      event.preventDefault();
      this.toggleDropdown();
    }
  }

  updateSelectedGoals() {
    const selectedValues = Array.from(document.querySelectorAll('input[name="assessment_goal_options[]"]:checked'))
                                .map(checkbox => checkbox.value);

    if (this.hasHiddenFieldTarget) {
      this.hiddenFieldTarget.value = selectedValues.join(",");
    }

    if (this.hasPlaceholderTarget) {
      this.placeholderTarget.value =
        selectedValues.length > 0
        ? `You selected ${selectedValues.length} goal${selectedValues.length > 1 ? 's' : ''}`
        : "Select Goals";
    }
  }

  closeDropdownOnClickOutside(event) {
    if (this.hasDropdownTarget && this.hasPlaceholderTarget) {
      if (
        this.dropdownTarget.style.display === "block" &&
        !this.dropdownTarget.contains(event.target) &&
        event.target !== this.placeholderTarget
      ) {
        this.dropdownTarget.style.display = "none";
        this.placeholderTarget.setAttribute("aria-expanded", "false");
      }
    }
  }

  checkCustomValidation() {
    if (this.hasAcademicStartDateTarget && this.academicStartDateTarget.value) {
      if (!this.isValidDate(this.academicStartDateTarget.value)) {
        this.academicStartDateTarget.setCustomValidity("Please enter a valid academic start date in the format MM-DD-YYYY.");
      } else {
        this.academicStartDateTarget.setCustomValidity("");
      }
    }

    if (this.hasAcademicEndDateTarget && this.academicEndDateTarget.value) {
      if (!this.isValidDate(this.academicEndDateTarget.value)) {
        this.academicEndDateTarget.setCustomValidity("Please enter a valid academic end date in the format MM-DD-YYYY.");
      } else {
        this.academicEndDateTarget.setCustomValidity("");
      }
    }
  }

  isValidDate(dateString) {
    let regex = /^\d{2}-\d{2}-\d{4}$/;

    if (!regex.test(dateString)) {
      return false;
    }

    let parts = dateString.split('-');
    let year = parseInt(parts[2], 10);
    let month = parseInt(parts[0], 10);
    let day = parseInt(parts[1], 10);
    if (year < 2000 || year > 2100 || month < 1 || month > 12 || day < 1 || day > 31){
      return false; // Invalid year, month, or day
    }
    return true;
  }

  formatDateInput(input) {
    input.addEventListener("input", function () {
      let value = input.value.replace(/\D/g, ""); // Remove non-numeric characters
      let formattedValue = "";

      if (value.length > 0) {
        formattedValue = value.substring(0, 2); // MM
      }
      if (value.length > 2) {
        formattedValue += "-" + value.substring(2, 4); // DD
      }
      if (value.length > 4) {
        formattedValue += "-" + value.substring(4, 8); // YYYY
      }

      input.value = formattedValue;
    });
    input.addEventListener("blur", () => {
      if (input !== this.startDateTarget) {
        let regex = /^(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])-(20\d\d)$/;
        let errorMsg;

        if (input.id === "academic-start-date-picker-edit") {
          errorMsg = document.querySelector(".aystart-error-msg");
        } else if (input.id === "academic-end-date-picker-edit") {
          errorMsg = document.querySelector(".ayend-error-msg");
        }

        if (input.value === '' || regex.test(input.value)) {
          input.classList.remove("error-border");
          errorMsg.classList.add("hidden");
        } else {
          input.classList.add("error-border");
          errorMsg.classList.remove("hidden");
        }
      }
    });
  }

  checkDateInput(input) {
    const element = document.querySelector('[data-controller~="edit-delivery"]');
    const schedulingFeatureFlag = element ? element.getAttribute('data-scheduling-form-flag') === 'true' : false;
    if (schedulingFeatureFlag) {
      const inputDate = new Date(input);
      const oneYearLater = new Date();
      oneYearLater.setFullYear(oneYearLater.getFullYear() + 1);

      if (inputDate > oneYearLater) {
        $(this.warningModalTarget).show();
      }
    }
  }

  hideWarningModal(){
    $(this.warningModalTarget).hide();
  }

  resetDates(){
    this.startDateTarget.value = '';
    this.hideWarningModal();
  }

  setMinScheduleDate(){
    const courseSchedulingFeatureFlag = this.element.dataset.schedulingFormFlag === "true";
    if (courseSchedulingFeatureFlag){
      this.startDateTarget.value = ''
      this.endDateTarget.value = ''
      const selectedCourse = this.examCourseTarget.value;
      const courseSchedules = JSON.parse(this.courseSchedulesTarget.value);
      let minDate = courseSchedules[selectedCourse] ? new Date(courseSchedules[selectedCourse]) : new Date();
      if (minDate < new Date()) {
          minDate = new Date();
      }
      this.minDate = minDate;
      $(this.startDateTarget).datepicker("option", "minDate", minDate);
    }
  }

  checkMinDate(dateString) {
    const inputDate = this.parseDate(dateString);
    const minDate = this.minDate || new Date(Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate()));
    const isValid = inputDate && inputDate >= minDate && this.isValidDate(dateString);
    const dateToUse = isValid ? inputDate : minDate;
    $(this.startDateTarget).datepicker("setDate", this.formatDate(dateToUse));
    this.checkDateInput(this.formatDate(dateToUse));

    // Calculate availability end date (10 days after start date)
    const availabilityEndsAt = new Date(dateToUse);
    availabilityEndsAt.setDate(availabilityEndsAt.getDate() + 10);

    // Update end datepicker options
    $(this.endDateTarget).datepicker("option", "minDate", dateToUse);
    $(this.endDateTarget).datepicker("option", "maxDate", availabilityEndsAt);

    this.resetEndTimeAndDateFieldsEdit();
  }

  parseDate(dateString) {
    const parts = dateString.split("-");
    const month = parseInt(parts[0], 10) - 1;
    const day = parseInt(parts[1], 10);
    const year = parseInt(parts[2], 10);
    return new Date(Date.UTC(year, month, day));
  }

  formatDate(date) {
    return `${String(date.getUTCMonth() + 1).padStart(2, '0')}-${String(date.getUTCDate()).padStart(2, '0')}-${date.getUTCFullYear()}`;
  }
}
