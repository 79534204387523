import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["topBar", "navWrapper"];

  get navWrapperHeight() {
    const nav = document.querySelector("#top-navigation-bar");
    const screenWidth = document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth;

    if ( window.devicePixelRatio >= 2 && screenWidth <= 767) {
      return 0
    }

    return nav?.offsetHeight || 0;
  }

  get topBarHeight() { return this.topBarTarget.offsetHeight; }

  get docBodyWrapper() { return document.querySelector("#doc-body-wrapper"); }

  connect() {
    this.lastScrollTop = 0;
    this.delta = 5;

    new ResizeObserver(() => {
      this.handleResize();
    }).observe(document.body);

    window.addEventListener("scroll", this.handleScroll.bind(this));
  }

  handleScroll() {
    const currentScroll = window.scrollY;

    // Check scroll direction
    if (Math.abs(this.lastScrollTop - currentScroll) <= this.delta) return;

    if (currentScroll > this.lastScrollTop) {
      // Scrolling down
      if (currentScroll > this.topBarHeight) {
        this.topBarTarget.style.top = `-${this.topBarHeight}px`;
      }
    } else {
      // Scrolling up
      this.topBarTarget.style.transition = "top 0.3s ease-in-out";
      this.topBarTarget.style.top = `${this.navWrapperHeight}px`;
    }

    this.lastScrollTop = currentScroll;
  }

  handleResize() {
    this.topBarTarget.style.top = `${this.navWrapperHeight}px`;
  }
}