import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["alphabetical", "chronological", "toggle", "toggleText", "alphabeticalExport", "chronologicalExport"]

  connect() {
    this.alphabeticalTarget.style.display = 'table'
    this.chronologicalTarget.style.display = 'none'
    this.updateView();
  }

  toggle(event) {
    if (event.target.checked) {
      this.alphabeticalTarget.style.display = 'none'
      this.chronologicalTarget.style.display = 'table'
    } else {
      this.alphabeticalTarget.style.display = 'table'
      this.chronologicalTarget.style.display = 'none'
    }
    this.updateView();
  }

  updateView() {
    if (this.toggleTarget.checked) {
      this.showChronologicalView();
    } else {
      this.showAlphabeticalView();
    }
  }

  showChronologicalView() {
    this.alphabeticalTarget.style.display = "none";
    this.chronologicalTarget.style.display = "table";

    this.alphabeticalExportTarget.style.display = "none";
    this.chronologicalExportTarget.style.display = "inline-block";

    this.toggleTextTarget.textContent = "Show data in alphabetical order by assessment";
  }

  showAlphabeticalView() {
    this.alphabeticalTarget.style.display = "table";
    this.chronologicalTarget.style.display = "none";

    this.alphabeticalExportTarget.style.display = "inline-block";
    this.chronologicalExportTarget.style.display = "none";

    this.toggleTextTarget.textContent = "Show data in chronological order";
  }
}
