import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["cardLoader", "cardContent", "questionModal"];

  connect() {
    if (this.hasCardLoaderTarget && this.hasCardContentTarget) {
      this.loadCardContent();
    }
  }

  loadCardContent() {
    const cardName = this.element.dataset.cardName;
    const cardLoader = this.cardLoaderTarget;
    const cardContent = this.cardContentTarget;
    const startDate = this.element.dataset.startDate;
    const endDate = this.element.dataset.endDate;
    cardLoader.style.display = "block";

    const params = new URLSearchParams({
      start_date: startDate,
      end_date: endDate,
    });

    fetch(`/educator_dashboard/${cardName}?${params.toString()}`)
      .then(response => response.text())
      .then(html => {
        cardContent.innerHTML = html;
      })
      .catch(error => {
        console.error("Error fetching data:", error);
        cardContent.innerHTML = "Error fetching data: " + error.message;
      })
      .finally(() => {
        cardLoader.style.display = "none";
        cardContent.style.opacity = "1";
      });
  }

  openQuestionModal(event){
    event.preventDefault();
    analytics.track('questions_about_data', {page: window.location.href, source: "Dashboard"})
    this.questionModalTarget.classList.remove("hidden");
    document.addEventListener("click", this.closeQuestionModal.bind(this));
  }

  closeQuestionModal(event){
    if(event.target.id === 'questionModal'){
      this.questionModalTarget.classList.add("hidden");
    }
  }

  updateDate(event) {
    const startDateInput = document.getElementById("start-date");
    const endDateInput = document.getElementById("end-date");
  
    // Get the previous valid values from data attributes
    const previousStartDate = startDateInput.dataset.previousValue || startDateInput.value;
    const previousEndDate = endDateInput.dataset.previousValue || endDateInput.value;
  
    const startDate = new Date(startDateInput.value);
    const endDate = new Date(endDateInput.value);
  
    if (startDate && endDate) {
      if (endDate < startDate) {
        alert("End date must be greater than the start date.");
  
        // Revert the field value to its previous valid value, formatted for the input type
        if (event.target.id === "end-date") {
          endDateInput.value = this.formatDateForInput(previousEndDate);
        } else if (event.target.id === "start-date") {
          startDateInput.value = this.formatDateForInput(previousStartDate);
        }
  
        return;
      }
    }
  
    // If validation passes, update the previous valid values in data attributes
    startDateInput.dataset.previousValue = startDateInput.value;
    endDateInput.dataset.previousValue = endDateInput.value;
  
    const form = document.getElementById("calendar-form");
    form.requestSubmit();
  }
  
  formatDateForInput(dateString) {
    const date = new Date(dateString);
    if (isNaN(date)) return ""; // Return empty string if the date is invalid
  
    const months = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
  
    const month = months[date.getMonth()]; // Get abbreviated month name
    const day = date.getDate(); // Day with leading zero
    const year = date.getFullYear(); // Full year
  
    // Return the formatted date in M d, yy format
    return `${month} ${day}, ${year}`;
  }
}