import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "modal", "assessmentWithoutForm" ];

  initialize() {
    this.handleKeyDown = this.handleKeyDown.bind(this);
    document.addEventListener("keydown", this.handleKeyDown);
  }

  connect() {
    if (this.hasAssessmentWithoutFormTarget) {
      this.alertForAssessmentWithoutForm();
    }
  }

  disconnect() {
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  handleKeyDown(event) {
    if (event.key === "Escape") {
      this.close();
      if (this.hasAssessmentWithoutFormTarget) {
        this.closeAlert();
      }
    }
  }

  open(e) {
    e.preventDefault();
    e.stopImmediatePropagation();

    const aqueductTable = window.AqueductTable;
    if (aqueductTable){
      aqueductTable.setState({query: {}});
    }

    this.modalTarget.classList.remove("hidden");

    // Ensure that the next tick focuses the first focusable element
    setTimeout(() => {
      this.setFocusOnFirstElement();
    }, 0);
  }

  close() {
    if (this.modalTarget) {
      const subscriberForm = document.querySelector('#subscriber-form');
      if (subscriberForm){
        subscriberForm.reset();
      }

      this.modalTarget.classList.add("hidden");
    }
  }

  setFocusOnFirstElement() {
    const focusableElements = this.modalTarget.querySelectorAll('input[type="checkbox"], button, [tabindex]:not([tabindex="-1"])');
    if (focusableElements.length > 0) {
      focusableElements[0].focus();
    }
  }

  alertForAssessmentWithoutForm(){
    $(this.assessmentWithoutFormTarget).show().css("background-color", "#2b2b2b42");
  }

  closeAlert(){
    $(this.assessmentWithoutFormTarget).hide();
  }
}
