import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["reset"];

  connect() {
    document.addEventListener("click", this.handleClickOutside.bind(this));
    this.handleKeydown = this.handleKeydown.bind(this);
    document.addEventListener('keydown', this.handleKeydown);
    this.setupEventListeners();
  }

  openResetModal() {
    this.resetTarget.classList.remove('hidden');
  }

  hideResetModal() {
    this.resetTarget.classList.add('hidden');
  }

  toggleDropdown() {
    if (window.innerWidth < 991) {
      const sidebar = document.querySelector('.quiz-sidebar')
      sidebar.classList.toggle('active')
      document.querySelector('.mobile-chevron')?.classList.toggle('rotate')
    }
  }

  keyPressToggleDropdown(event) {
    if (event.key === "Enter") {
      this.toggleDropdown()
    }
  }

  handleClickOutside(event) { 
    const sidebar = document.querySelector('.quiz-sidebar');
    const chevron = document.querySelector('.mobile-chevron');

    if(sidebar && sidebar.classList.contains('active') && event.target != chevron && !sidebar.contains(event.target)) {
      sidebar.classList.toggle('active')
      chevron?.classList.toggle('rotate')
    }
  }

  handleModalKeydown(event, modal, hideModalMethod) {
    let submitButton = modal.querySelector('input[type="submit"]');
    let cancelButton = modal.querySelector('.cancel-button-class');

    if (event.key === 'Enter') {
      // Trigger the submit button click event
      var resetLink = document.getElementById('reset-link');
      if (resetLink) {
        // Trigger the link click event
        resetLink.click();
      }
    } else if (event.key === 'Escape') {
      // Trigger the cancel button click event
      this[hideModalMethod](event);
    }
  }

  handleKeydown(event) {
    if (this.hasResetTarget && !this.resetTarget.classList.contains('hidden')) {
      this.handleModalKeydown(event, this.resetTarget, 'hideResetModal');
    }
  }

  setupEventListeners() {
    const reportButtons  = document.querySelectorAll(".report-issue");
    const modalContainer = document.querySelector(".report-modal-container");
    const form           = document.getElementById("reportIssueForm");
    const modalCloseButtons   = document.querySelectorAll(".ri-close");

    reportButtons.forEach(button => {
      button.addEventListener("click", () => {
        if (modalContainer) {
          form.reset(); // reset the form
          modalContainer.style.display = "block";
        }
      });
    });

    modalCloseButtons.forEach(button => {
      button.addEventListener("click", () => {
        if (modalContainer) {
          modalContainer.style.display = "none";
        }
      });
    });

    document.addEventListener("keydown", (event) => {
      if (event.key === "Escape" && modalContainer && modalContainer.style.display === "block") {
        modalContainer.style.display = "none";
      }
    });
  }
}
