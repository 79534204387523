import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "email", "phone", "startDate", "startTime",
                     "endDate", "endTime", "programType", "examCourse",
                     "academicStartDate","academicEndDate","aqueductCard",
                     "purpose", "rosterings" , "warningModal", "courseSchedules"]

  initialize() {
    if (this.hasEmailTarget && !this.emailTarget) return;

    const urlParams = new URLSearchParams(window.location.search);
    const preserveDates = urlParams.get('preserve_dates') === 'true';
    const preserveAssessment = urlParams.get('preserve_assessment') === 'true';
    const schedulingFormFeature = this.element.dataset.schedulingFormFlag === "true";
    const shouldReloadExamCourses = this.hasExamCourseTarget && !schedulingFormFeature;

    if ((preserveDates || preserveAssessment) && shouldReloadExamCourses) {
      this.reloadExamCourses();
    }

    const datepickerOptions = {
      numberOfMonths: 2,
      dateFormat: "mm-dd-yy",
      minDate: 0,
      onSelect: (date) => {
        $("#date_changed").click();
        this.startDateSelected(date);
        if (schedulingFormFeature) {
          this.checkDateInput(date);
        } else if (!preserveDates && !preserveAssessment) {
          this.reloadExamCourses();
        }
      }
    };

    // Setup Start DatePicker
    $(this.startDateTarget)
      .datepicker(datepickerOptions)
      .on("change", (event) => {
        const inputValue = event.target.value.trim();
        if (inputValue) {
          this.checkMinDate(inputValue);
          if (!schedulingFormFeature) this.reloadExamCourses();
        }
      });

    // Setup End DatePicker
    $(this.endDateTarget).datepicker({
      numberOfMonths: 2,
      dateFormat: "mm-dd-yy",
      onSelect: (date) => {
        this.endDateSelected(date);
        $("#date_changed").click();
      }
    }).on('keypress', function(e){ e.preventDefault(); });

    // Initial field states
    if (this.hasEndDateTarget) setInactive(this.endDateTarget);

    // Prevent the user from being able to submit the form with Enter
    $(document).ready(function () {
      $(window).keydown(function (event) {
        if (event.keyCode == 13) {
          event.preventDefault();
          return false;
        }
      });
    });

    // Attach event listeners for academic date fields
    if (this.hasStartDateTarget) this.formatDateInput(this.startDateTarget);
    if (this.hasAcademicStartDateTarget) this.formatDateInput(this.academicStartDateTarget);
    if (this.hasAcademicEndDateTarget) this.formatDateInput(this.academicEndDateTarget);
  }

  // Are we in the Calibrate Demonstration Program?
  get isDemo() {
    return JSON.parse(this.element.dataset.demo)
  }

  // Number of days between start date and end date in seconds
  get timeDiff() {
    return new Date(this.endDateTarget.value) - new Date(this.startDateTarget.value)
  }

  //////////////////////
  // Stimulus Actions //
  //////////////////////
  reloadExamCourses() {
    const url = this.examCourseTarget.dataset.url && new URL(this.examCourseTarget.dataset.url)
    const preserveDates = new URLSearchParams(window.location.search).get('preserve_dates') === 'true';

    if (url) {
      if (preserveDates) {
        url.searchParams.append('start_date', this.examCourseTarget.dataset.startDate)
      } else if (this.startDateTarget.value) {
        url.searchParams.append('start_date', this.startDateTarget.value)
      } else {
        url.searchParams.append('start_date', new Date().toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: '2-digit' }));
      }
    }

    fetch(url)
      .then(response => response.text())
      .then(html => {
        const tempContainer = document.createElement('div');
        tempContainer.innerHTML = html.trim();

        // Find the new select element inside the fetched HTML
        const newSelect = tempContainer.querySelector('select');

        if (newSelect) {
          // Replace the current examCourseTarget with the new select element
          this.examCourseTarget.replaceWith(newSelect);

          // Optionally, set the selected option
          this.setSelectedExamCourse();
        }
      })
  }

  setSelectedExamCourse() {
    const selectedCourse = document.getElementById('hidden_exam_course')?.value;

    if (selectedCourse) {
      const selectedOption = Array.from(this.examCourseTarget.options).find(
        option => option.value === selectedCourse
      );

      if (selectedOption) {
        selectedOption.selected = true;
      }
    }
  }

  startDateSelected(windowStartDate) {
    setActive(this.endDateTarget);

    this.startDateTarget.classList.add("date-selected");
    $(this.endDateTarget).datepicker("option", "minDate", windowStartDate);
    this.endDateTarget.value = '';
    if (this.isDemo) {
      const diff = new Date(this.endDateTarget.value) - new Date(this.startDateTarget.value)
      this.#rebuildEndTimeSelectOptions(this.timeDiff);
    } else {
      const windowEndDate = new Date(windowStartDate);
      windowEndDate.setDate(windowEndDate.getDate() + 10);
      $(this.endDateTarget).datepicker("option", "maxDate", windowEndDate);
    }
  }

  startTimeSelected() {
    if (this.isDemo) return
  }

  endDateSelected(date) {
    this.endDateTarget.classList.add("date-selected");
  }

  checkRosteringPresence(ev) {
    const aqueductTable = window.AqueductTable;
    const rows          = aqueductTable.state.rows;

    if (!rows.some(row => row.selected)) {
      let answer = confirm('You have not selected any students. Do you want to request the delivery without any students?')
      if (!answer) {
        ev.preventDefault()
      }
    }

    if (aqueductTable){
      aqueductTable.setState({query: {}});
    }
  }

  checkCustomValidities() {
    if (this.hasEmailTarget) {
      this.emailTarget.setCustomValidity(
        this.emailTarget.validity.valueMissing ? "Provide contact email" : ""
      );
    }

    if (this.hasPhoneTarget) {
      this.phoneTarget.setCustomValidity(
        this.phoneTarget.validity.valueMissing ? "Provide contact phone" : ""
      );
    }

    this.startDateTarget.setCustomValidity(
      this.startDateTarget.validity.valueMissing ? "Select start date" : ""
    );

    this.startTimeTarget.setCustomValidity(
      this.startTimeTarget.validity.valueMissing ? "Select start time" : ""
    );

    this.endDateTarget.setCustomValidity(
      this.endDateTarget.validity.valueMissing ? "Select end date" : ""
    );

    this.endTimeTarget.setCustomValidity(
      this.endTimeTarget.validity.valueMissing ? "Select end time" : ""
    );

    this.programTypeTarget.setCustomValidity(
      this.programTypeTarget.validity.valueMissing ? "Select program type" : ""
    );

    this.examCourseTarget.setCustomValidity(
      this.examCourseTarget.validity.valueMissing ? "Select course" : ""
    );

    this.purposeTarget.setCustomValidity(
      this.purposeTarget.validity.valueMissing ? "Select purpose" : ""
    );

    if (this.hasAcademicStartDateTarget && this.academicStartDateTarget.value) {
      if (!this.isValidDate(this.academicStartDateTarget.value)) {
        this.academicStartDateTarget.setCustomValidity("Please enter a valid academic start date in the format MM-DD-YYYY.");
      } else {
        this.academicStartDateTarget.setCustomValidity("");
      }
    }

    if (this.hasAcademicEndDateTarget && this.academicEndDateTarget.value) {
      if (!this.isValidDate(this.academicEndDateTarget.value)) {
        this.academicEndDateTarget.setCustomValidity("Please enter a valid academic end date in the format MM-DD-YYYY.");
      } else {
        this.academicEndDateTarget.setCustomValidity("");
      }
    }
    this.updateSelectedGoals();
    this.endDateTarget.disabled = false;
  }


  isValidDate(dateString) {
    let regex = /^\d{2}-\d{2}-\d{4}$/;

    if (!regex.test(dateString)) {
      return false;
    }

    let parts = dateString.split('-');
    let year = parseInt(parts[2], 10);
    let month = parseInt(parts[0], 10);
    let day = parseInt(parts[1], 10);
    if (year < 2000 || year > 2100 || month < 1 || month > 12 || day < 1 || day > 31){
      return false; // Invalid year, month, or day
    }
    return true;
  }

  #rebuildEndTimeSelectOptions(diff = null) {
    const optionsArray = [...this.startTimeTarget.options]

    const appendEndTimeOptions = (startIndex, endIndex) => {
      this.endTimeTarget.options.length = 0
      for (let i = startIndex; i <= endIndex; i++) {
        appendSelectOption(this.endTimeTarget, optionsArray[i])
      }
    }

    switch(diff) {
      case 0:
        appendEndTimeOptions(this.startTimeTarget.selectedIndex, optionsArray.length-1)
        break;
      case 864000000:
        appendEndTimeOptions(0, this.startTimeTarget.selectedIndex)
        break;
      case 860400000:
        appendEndTimeOptions(0, Math.min(this.startTimeTarget.selectedIndex+1, optionsArray.length-1))
        break;
      case 867600000:
        appendEndTimeOptions(0, Math.max(this.startTimeTarget.selectedIndex-1, 1))
        break;
      default:
        appendEndTimeOptions(0, optionsArray.length-1)
    }

    function appendSelectOption(select, option) {
      const optionEl = document.createElement("option")
      optionEl.value = option.value
      optionEl.innerHTML = option.innerHTML
      select.appendChild(optionEl)
    }
  }

  formatDateInput(input) {
    input.addEventListener("input", function () {
      let value = input.value.replace(/\D/g, ""); // Remove non-numeric characters
      let formattedValue = "";

      if (value.length > 0) {
        formattedValue = value.substring(0, 2); // MM
      }
      if (value.length > 2) {
        formattedValue += "-" + value.substring(2, 4); // DD
      }
      if (value.length > 4) {
        formattedValue += "-" + value.substring(4, 8); // YYYY
      }

      input.value = formattedValue;
    });
    input.addEventListener("blur", () => {
      if (input !== this.startDateTarget) {
        let regex = /^(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])-(19|20)\d\d$/;
        if (input.value === '' || regex.test(input.value)) {
          input.classList.remove("error-border");
        } else {
          input.classList.add("error-border");
        }
      }
    });
  }

  checkDateInput(input) {
    const element = document.querySelector('[data-controller~="new-delivery"]');
    const schedulingFeatureFlag = element ? element.getAttribute('data-scheduling-form-flag') === 'true' : false;
    if (schedulingFeatureFlag) {
      const inputDate = new Date(input);
      const oneYearLater = new Date();
      oneYearLater.setFullYear(oneYearLater.getFullYear() + 1);

      if (inputDate > oneYearLater) {
        $(this.warningModalTarget).show();
      }
    }
  }

  hideWarningModal(){
    $(this.warningModalTarget).hide();
  }

  resetDates(){
    this.startDateTarget.value = '';
    this.hideWarningModal();
  }

  setMinScheduleDate(){
    const courseSchedulingFeatureFlag = this.element.dataset.schedulingFormFlag === "true";
    if (courseSchedulingFeatureFlag){
      this.startDateTarget.value = ''
      this.endDateTarget.value = ''
      const selectedCourse = this.examCourseTarget.value;
      const courseSchedules = JSON.parse(this.courseSchedulesTarget.value);
      let minDate = courseSchedules[selectedCourse] ? new Date(courseSchedules[selectedCourse]) : new Date();
      if (minDate < new Date()) {
          minDate = new Date();
      }
      this.minDate = minDate;
      $(this.startDateTarget).datepicker("option", "minDate", minDate);
    }
  }

  checkMinDate(dateString) {
    const inputDate = this.parseDate(dateString);
    const minDate = this.minDate || new Date(Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate()));
    const isValid = inputDate && inputDate >= minDate && this.isValidDate(dateString);
    const dateToUse = isValid ? inputDate : minDate;
    $(this.startDateTarget).datepicker("setDate", this.formatDate(dateToUse));
    this.startDateSelected(this.formatDate(dateToUse));
    this.checkDateInput(this.formatDate(dateToUse));
  }

  parseDate(dateString) {
    const parts = dateString.split("-");
    const month = parseInt(parts[0], 10) - 1;
    const day = parseInt(parts[1], 10);
    const year = parseInt(parts[2], 10);
    return new Date(Date.UTC(year, month, day));
  }

  formatDate(date) {
    return `${String(date.getUTCMonth() + 1).padStart(2, '0')}-${String(date.getUTCDate()).padStart(2, '0')}-${date.getUTCFullYear()}`;
  }

  updateSelectedGoals() {
    const selectedValues = Array.from(document.querySelectorAll('input[name="assessment_goal_options[]"]:checked'))
                                .map(checkbox => checkbox.value);
    const hiddenField = document.getElementById('hidden-assessment-goals');
    const placeholder = document.getElementById('assessment-goal-placeholder');

    if (hiddenField){
      hiddenField.value = selectedValues.join(',');
    }
    if (placeholder) {
      placeholder.value = selectedValues.length > 0 ? `You selected ${selectedValues.length} goal${selectedValues.length > 1 ? 's': ''}` : 'Select Goals';
    }
  };
}

function setInactive(element) {
  element.disabled = true;
  element.style.background = "lightgray";
  element.style.color = "#a6a6a6";
}

function setActive(element) {
  element.disabled = false;
  element.style.background = "";
  element.style.color = "";
}
