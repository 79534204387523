import { Controller } from "@hotwired/stimulus"
import * as React from "react"
import { createRoot } from "react-dom/client"
import CurricularSummarySystem from "../src/react/components/Assessment/Reports/CurricularPerformanceSummary/curricular_summary_system";
import CurricularSummaryFocus from "../src/react/components/Assessment/Reports/CurricularPerformanceSummary/curricular_summary_focus";
import CurricularSummaryDiscipline from "../src/react/components/Assessment/Reports/CurricularPerformanceSummary/curricular_summary_discipline";


// Connects to data-controller="multi-cohort-curricular-summary-tab"
export default class extends Controller {
  static targets = ["CurricularPerformancesSummary"];

  connect() {
    let reportData = JSON.parse(this.element.dataset.reportData);
    createRoot(this.CurricularPerformancesSummaryTarget).render(
      <>
        <CurricularSummaryDiscipline {...reportData} />
        <CurricularSummarySystem {...reportData} />
        <CurricularSummaryFocus {...reportData} />
      </>
    );
  }
}
