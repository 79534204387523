import { Controller } from "@hotwired/stimulus";
import * as React from "react";
import { createRoot } from "react-dom/client";
import CurricularSummarySystem from "../src/react/components/Assessment/Reports/CurricularPerformanceSummary/curricular_summary_system";
import CurricularSummaryFocus from "../src/react/components/Assessment/Reports/CurricularPerformanceSummary/curricular_summary_focus";

// Connects to data-controller="curricular-summary-tab"
export default class extends Controller {
  static targets = ["CurricularPerformanceSummary"];

  connect() {
    let reportData = JSON.parse(this.element.dataset.reportData);
    createRoot(this.CurricularPerformanceSummaryTarget).render(
      <>
        <CurricularSummarySystem {...reportData} />
        <CurricularSummaryFocus {...reportData} />
      </>
    );
  }
}
