import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "collapsibleElement", "collapserControl" ]

  toggleCollapse(ev) {
    if (this.collapserControlTarget.classList.contains("icon-open-box") || 
        this.collapserControlTarget.classList.contains("icon-close-box")) {
      this.collapserControlTarget.classList.toggle("icon-open-box");
      this.collapserControlTarget.classList.toggle("icon-close-box");
      this.collapsibleElementTarget.classList.toggle('hidden');
    } else if (this.collapserControlTarget.classList.contains("open-icon") || 
               this.collapserControlTarget.classList.contains("closed-icon")) {
      this.collapserControlTarget.classList.toggle("open-icon");
      this.collapserControlTarget.classList.toggle("closed-icon");
      this.collapsibleElementTarget.classList.toggle('hidden');
    }
  }
  
}
