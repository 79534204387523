import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["menu","submenu","arrow"];

  connect() {
    const shownMenuId = localStorage.getItem("shownDropdownId");
    if (shownMenuId) {
      const shownMenu = document.getElementById(shownMenuId);
      if (shownMenu) {
        shownMenu.removeAttribute("hidden");
        document.addEventListener("click", this.closeDropdowns);
      }
    }
    this.changeBackground();
  }

  changeBackground() {
    const checkboxes = this.element.querySelectorAll('input[type="checkbox"]');
    const button = this.element.querySelector('.hotwire-button');
    if (!button) { return null; }
    const isChecked = Array.from(checkboxes).some(checkbox => checkbox.checked);
    if (isChecked) {
      button.style.backgroundColor = '#D9D9D9';
    } else {
      button.style.backgroundColor = 'white';
    }
  }

  toggle(event) {
      event.preventDefault();
      if (this.hasArrowTarget) {
        this.arrowTarget.classList.toggle("flipped");
      }
      if (!this.menuTarget.classList.contains("hidden")) {
          document.addEventListener("click", this.closeDropdowns)
      } else {
          document.removeEventListener("click", this.closeDropdowns)
      }
      const currentMenu = this.menuTarget;
      const allMenus = document.querySelectorAll(".dropdown-list");
      allMenus.forEach((menu) => {
          if (menu !== currentMenu) {
              menu.setAttribute("hidden", "");
          }
      });
      if (currentMenu.hasAttribute("hidden")) {
          currentMenu.removeAttribute("hidden");
          localStorage.setItem("shownDropdownId", currentMenu.id);
      } else {
          currentMenu.setAttribute("hidden", "");
          localStorage.removeItem("shownDropdownId");
      }
      this.changeBackground();
  }

  toggleSubmenu(event) {
      event.preventDefault();
      if (this.hasSubmenuTarget) { // Check if submenuTarget exists
          const submenu = this.submenuTarget;
          const allSubmenus = document.querySelectorAll("[data-target='dropdown.submenu']");
          allSubmenus.forEach((sub) => {
              if (sub !== submenu) {
                  sub.setAttribute("hidden", "");
              }
          });
          if (submenu.hasAttribute("hidden")) {
              submenu.removeAttribute("hidden");
          } else {
              submenu.setAttribute("hidden", "");
          }
      }
  }

  closeDropdowns = (event) => {
      if (!event.target.closest("[data-controller='dropdown']")) {
          this.menuTarget.setAttribute("hidden", "");
          if (this.hasSubmenuTarget) {
              this.submenuTarget.setAttribute("hidden", ""); // Close the submenu if it exists
          }
          document.removeEventListener("click", this.closeDropdowns)
          localStorage.removeItem("shownDropdownId");
      }
  }

  toggleOnEnter(event) {
    if (event.keyCode === 13) {
      this.toggle(event)
    }
  }

  toggleEducatorDashboardSort(event){
    if (this.hasArrowTarget) {
      this.arrowTarget.classList.toggle("flipped");
    }
    if (!this.menuTarget.classList.contains("hidden")) {
        document.addEventListener("click", this.closeDropdowns)
    } else {
        document.removeEventListener("click", this.closeDropdowns)
    }
    const currentMenu = this.menuTarget;
    const allMenus = document.querySelectorAll(".dropdown-list");
    allMenus.forEach((menu) => {
        if (menu !== currentMenu) {
            menu.setAttribute("hidden", "");
        }
    });
    if (currentMenu.hasAttribute("hidden")) {
        currentMenu.removeAttribute("hidden");
        localStorage.setItem("shownDropdownId", currentMenu.id);
    } else {
        currentMenu.setAttribute("hidden", "");
        localStorage.removeItem("shownDropdownId");
    }
    this.changeBackground();
  }
}