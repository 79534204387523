import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["addModal", "unknownModal", "add", "unknown", "summaryTable"];

  connect() {
    this.selectedStudents = this.selectedStudents || [];
  }

  connect() {
    document.addEventListener("keydown", this.handleKeydown);
  }

  disconnect() {
    document.removeEventListener("keydown", this.handleKeydown);
  }

  handleKeydown = (event) => {
    if (event.key === "Escape") {
      this.closeAdd();
      this.closeUnknown();
    }
  };

  openAdd() {
    this.addModalTarget.classList.remove("hidden");
    this.addModalTarget.querySelector("[data-target='batch-add.add']").focus();
    document.addEventListener("keydown", this.handleKeydown);
  }

  closeAdd() {
    this.addModalTarget.classList.add("hidden");
    document.removeEventListener("keydown", this.handleKeydown);
    this.clearTextarea();
  }

  openUnknown() {
    this.unknownModalTarget.classList.remove("hidden");
    document.addEventListener("keydown", this.handleKeydown);
  }

  closeUnknown() {
    this.unknownModalTarget.classList.add("hidden");
    document.removeEventListener("keydown", this.handleKeydown);
  }

  handleKeydown = (event) => {
    if (event.key === "Escape") {
      this.closeAdd();
      this.closeUnknown();
    } else if (event.key === "Enter") {
      this.add();
    }
  };

  selectRows(items, prop) {
    const aqueductTable = window.AqueductTable;
    const rows = aqueductTable.state.rows;
    let newRows = [];
    let foundItems = [];
    for (const row of rows) {
      if (items.includes(row[prop])) {
        row.selected = true;
        foundItems.push(row[prop]);
        newRows.unshift(row);
      } else {
        newRows.push(row);
      }
    }
    aqueductTable.setState({ rows: newRows });
    this.closeAdd();
    if (foundItems.length !== items.length) {
      let unknownItems = items.filter(item => !foundItems.includes(item));
      if (unknownItems.length) {
        this.unknownTarget.value = unknownItems.join(", ");
        this.openUnknown();
      }
    }
  }

  add() {
    if (this.hasAddTarget && this.addTarget.value.length) {
      let items = [];
      try {
        items = this.addTarget.value.toLowerCase().split(/[,;|/\s]/).filter(a => a !== '');
      } catch (e) {
        console.log(e);
      }

      if (this.hasSummaryTableTarget) {
        this.selectSummaryTableRows(items, this.addTarget.dataset.prop);
      } else {
        this.selectRows(items, this.addTarget.dataset.prop);
      }
      this.clearTextarea();
    } else {
      $('#error').show();
    }
  }

  copy() {
    if (this.hasUnknownTarget && this.unknownTarget.value.length) {
      this.unknownTarget.select();
      this.unknownTarget.setSelectionRange(0, 99999);
      try {
        document.execCommand('copy');
        console.log("Copied the text: " + this.unknownTarget.value);
      } catch (e) {
        console.log(e)
      }
    }
  }

  selectSummaryTableRows(items, prop) {
    if (this.hasSummaryTableTarget) {
      const aqueductTable = document.getElementById('SummaryTable');
      const tbody = aqueductTable.querySelector('tbody');
      const rows = Array.from(tbody.rows);
      
      const selectedStudents = Array.isArray(this.selectedStudents) ? [...this.selectedStudents] : [];
      const newlySelected = [];
  
      for (const row of rows) {
        try {
          const studentInfo = JSON.parse(row.getAttribute('data-student-info') || "{}");
          if (studentInfo) {
            const cellValue = studentInfo[prop];
            const studentId = studentInfo["id"].toString();
            const checkbox = row.querySelector('input[type="checkbox"]');
  
            if (items.includes(cellValue)) {
              if (checkbox) {
                checkbox.checked = true;
                if (!selectedStudents.includes(studentId)) {
                  selectedStudents.push(studentId);
                  newlySelected.push(cellValue);
                  checkbox.dispatchEvent(new Event("change", { bubbles: true }));
                }
              }
            }
  
            if (checkbox && checkbox.checked) {
              tbody.prepend(row);
            }
          }
        } catch (error) {
          console.error("Error parsing student info:", error);
        }
      }
  
      this.selectedStudents = selectedStudents;
      tbody.querySelectorAll('input[type="checkbox"]:checked').forEach((checkbox) => {
        checkbox.dispatchEvent(new Event("change", { bubbles: true }));
      });
      this.dispatchSelectedStudentsEvent();
      this.closeAdd();

      let allFoundItems = [...newlySelected, ...this.selectedStudents.map(id => {
        const row = rows.find(r => JSON.parse(r.getAttribute('data-student-info') || "{}").id.toString() === id);
        return row ? JSON.parse(row.getAttribute('data-student-info') || "{}")[prop] : null;
      }).filter(Boolean)];

      let unknownItems = items.filter(item => !allFoundItems.includes(item));
      if (unknownItems.length) {
        this.unknownTarget.value = unknownItems.join(", ");
        this.openUnknown();
      }
    }
  }

  dispatchSelectedStudentsEvent() {
    this.element.dispatchEvent(new CustomEvent("students:selected", {
      detail: { selectedStudents: this.selectedStudents },
      bubbles: true
    }));
  }

  clearTextarea() {
    if (this.hasAddTarget) {
      this.addTarget.value = "";
    }
  }  
}
