import { Controller } from "@hotwired/stimulus";
import React from "react";

export default class extends Controller {
  static targets = ["filterDropdown", "selectedGroups", "documentSetId","customCourses", "assessmentDeliveries", "customCoursesFilter", "assessmentDeliveriesFilter", "summaryTable", "addSelectedGroup"];

  connect() {
    this.selectedStudents = this.selectedStudents || [];
    this.selectedCustomCourses = {};
    this.selectedAssessmentDeliveries = {};
    this.currentCustomCourses = {};
    this.currentAssessmentDeliveries = {};
    if (this.hasDocumentSetIdTarget){
      this.fetchStudentsForDocumentSet();
    }
    this.selectedStudentsByGroup = this.selectedStudentsByGroup || {};
  }

  fetchStudentsForDocumentSet() {
    const customCourseId = this.documentSetIdTarget.value;
    const customCoursesData = JSON.parse(this.element.dataset.groupSelectionCustomCourses);

    if (customCourseId) {
      // Find the custom course corresponding to the documentSetId
      const selectedCourse = this.findCourseById(customCoursesData,customCourseId);

      if (selectedCourse) {
        // Set the default selected custom course based on the documentSetId
        this.selectedCustomCourses[selectedCourse.id] = `${selectedCourse.name}(${selectedCourse.start_date} - ${selectedCourse.end_date})`;

        // Update the hidden input field
        this.updateHiddenInput(this.selectedCustomCourses, this.selectedAssessmentDeliveries);
        this.currentCustomCourses = { ...this.selectedCustomCourses };

        // Display the selected course in the modal
        this.updateSelectedGroups();
      }
    }
  }

  // Find a course by its ID from the passed customCoursesData
  findCourseById(customCoursesData, courseId) {
    return customCoursesData.find(course => course.id == courseId);
  }

  // Fetch students based on filters
  async fetchStudents(event) {
    event.preventDefault();

    try {
      const url = this.addSelectedGroupTarget.dataset.url;
      const filterData = this.getFilterData();
      const selectedGroupsBlock = document.getElementById("selected_groups_block");
      const modalTarget = document.querySelector('[data-modal-target="modal"]');
      const response = await this.sendPostRequest(url, filterData);

      if (response.ok) {
        if (selectedGroupsBlock) {
          selectedGroupsBlock.style.display = "block";
          this.updateSelectedGroupInForm();
        }
        const data = await response.json();
        const grduIds = data.grdus.map(grdu => grdu.id); // Store GRDU ids
        const groupIds = filterData.custom_courses.length > 0 ? filterData.custom_courses : [];
        const deliveryIds = filterData.assessment_deliveries.length > 0 ? filterData.assessment_deliveries : [];

        if (grduIds.length) {
          // Select rows based on the GRDU id
          if (this.hasSummaryTableTarget) {
            groupIds.forEach(groupId => {
              this.selectSummaryTableRows(grduIds, "id", groupId, null);
            });

            deliveryIds.forEach(deliveryId => {
              this.selectSummaryTableRows(grduIds, "id", null, deliveryId);
            });          
          } else {
            this.selectRows(grduIds, "grdu_id");
          }
        }
        this.currentCustomCourses = { ...this.selectedCustomCourses };
        this.currentAssessmentDeliveries = { ...this.selectedAssessmentDeliveries };
        modalTarget.classList.add("hidden");
      } else {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }

  // Extract filter data from the dropdown
  getFilterData() {
    return JSON.parse(this.filterDropdownTarget.value);
  }

  // Send a POST request
  async sendPostRequest(url, filterData) {
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
      },
      body: JSON.stringify({ filterData }),
    });
  }

  selectRows(items, prop) {
    const aqueductTable = window.AqueductTable;
    const rows = aqueductTable.state.rows;
    let newRows = [];
    let foundItems = [];
    // Loop through each row and check if the current row's `prop` (e.g. 'id') is in the items list
    for (const row of rows) {
      if (items.includes(row[prop])) {
        row.selected = true;
        foundItems.push(row[prop]);
        newRows.unshift(row); // Add selected rows at the beginning
      } else {
        newRows.push(row);
      }
    }

    // Update the table state with the new rows
    aqueductTable.setState({ rows: newRows });
  }

  unselectAllRows(){
    if (this.hasSummaryTableTarget) {
      this.unselectSummaryTableRows();
    } else {
      const aqueductTable = window.AqueductTable;
      const rows = aqueductTable.state.rows;

      for (const row of rows) {
        row.selected = false;
      }

      // Update the table state with the rows
      aqueductTable.setState({ rows: rows });
    }
  }

  // Handle custom courses selection
  updateCustomCourses(event) {
    this.updateSelection(
      event,
      this.selectedCustomCourses,
      "custom_courses"
    );
  }

  // Handle assessment deliveries selection
  updateAssessmentDeliveries(event) {
    this.updateSelection(
      event,
      this.selectedAssessmentDeliveries,
      "assessment_deliveries"
    );
  }

  // General method to update selection based on the event
  updateSelection(event, selectedItems, key) {
    const element = event.target;
    const id = parseInt(element.value);
    const displayText = element.parentElement.querySelector("label").textContent.toUpperCase();

    if (element.checked) {
      selectedItems = { ...selectedItems, [id]: displayText };
    } else {
      selectedItems = Object.fromEntries(
        Object.entries(selectedItems).filter(([key]) => parseInt(key) !== id)
      );
    }

    if (key === "custom_courses") {
      this.selectedCustomCourses = selectedItems;
    } else if (key === "assessment_deliveries") {
      this.selectedAssessmentDeliveries = selectedItems;
    }

    this.updateHiddenInput(this.selectedCustomCourses, this.selectedAssessmentDeliveries);
    this.updateSelectedGroups();
  }

  // Update the hidden input with selected values
  updateHiddenInput(selectedCustomCourses, selectedAssessmentDeliveries) {
    const filterData = {
      custom_courses: Object.keys(selectedCustomCourses).map(Number),
      assessment_deliveries: Object.keys(selectedAssessmentDeliveries).map(Number),
    };

    this.filterDropdownTarget.value = JSON.stringify(filterData);
    const hiddenCustomCourseField = document.getElementById('assessment_delivery_custom_course_ids');

    if (hiddenCustomCourseField) {
      hiddenCustomCourseField.value = filterData.custom_courses.join(',');
    }
  }

  // Update the selected groups display
  updateSelectedGroups() {
    this.selectedGroupsTarget.innerHTML = this.generateSelectedGroupsHTML(
      this.selectedCustomCourses,
      this.selectedAssessmentDeliveries
    );
  }

  // Generate HTML for selected groups
  generateSelectedGroupsHTML(customCourses, assessmentDeliveries, isForm = false) {
    const generateHTML = (items, type) =>
      Object.entries(items)
        .map(
          ([id, text]) =>
            `<div class="col-12 col-sm-6 d-flex align-items-center mt-2">
              <p class="group-sel">${text}</p>
              ${!isForm
                ? `<button type="button" class="btn rm-hover btn-bold btn-sm ml-2" data-id="${id}" data-type="${type}" data-action="click->group-selection#removeGroup">X</button>`
                : ""}
            </div>`
        )
        .join("");

    return (
      generateHTML(customCourses, "custom_course") +
      generateHTML(assessmentDeliveries, "assessment_delivery")
    );
  }

  // Remove a group from the selected list
  removeGroup(event) {
    const { id, type } = event.target.dataset;
    const parsedId = parseInt(id);

    if (type === "custom_course") {
      delete this.selectedCustomCourses[parsedId];
      this.unselectStudentsByGroup(parsedId, null);
    } else if (type === "assessment_delivery") {
      delete this.selectedAssessmentDeliveries[parsedId];
      this.unselectStudentsByGroup(null, parsedId);
    }

    this.updateHiddenInput(this.selectedCustomCourses, this.selectedAssessmentDeliveries);
    this.updateSelectedGroups();
  }

  updateSelectedGroupInForm(){
    const selectedGroupsList = document.getElementById("selected_groups_list");
    if (selectedGroupsList) {
      selectedGroupsList.innerHTML = this.generateSelectedGroupsHTML(
        this.selectedCustomCourses,
        this.selectedAssessmentDeliveries,
        true
      );
    }
  }

  openGroupSelectionModal() {
    if (this.currentCustomCourses || this.currentAssessmentDeliveries) {
      this.selectedCustomCourses = this.currentCustomCourses;
      this.selectedAssessmentDeliveries = this.currentAssessmentDeliveries;
      this.updateHiddenInput(this.currentCustomCourses, this.currentAssessmentDeliveries)
      this.updateSelectedGroups();
      this.selectAlreadySelected(this.filterDropdownTarget.value);
    }
     document.addEventListener("keydown", this.handleKeydown);
  }

  selectAlreadySelected(data) {
    try {
      this.customCoursesTargets.forEach((checkbox) => (checkbox.checked = false));
      this.assessmentDeliveriesTargets.forEach((checkbox) => (checkbox.checked = false));
      let parsedData = JSON.parse(data);

      if (parsedData.custom_courses) {
        parsedData.custom_courses.forEach((courseId) => {
          let checkbox = this.customCoursesTargets.find(
            (cb) => cb.value == courseId // Match checkbox value with courseId
          );
          if (checkbox) {
            checkbox.checked = true;
          }
        });
      }

      if (parsedData.assessment_deliveries) {
        parsedData.assessment_deliveries.forEach((deliveryId) => {
          let checkbox = this.assessmentDeliveriesTargets.find(
            (cb) => cb.value == deliveryId // Match checkbox value with deliveryId
          );
          if (checkbox) {
            checkbox.checked = true;
          }
        });
      }
    } catch (error) {
      console.error("Invalid JSON Data:", error);
    }
  }

  selectSummaryTableRows(items, prop, groupId = null, deliveryId = null) {
    if (this.hasSummaryTableTarget) {
      const aqueductTable = document.getElementById('SummaryTable');
      const tbody = aqueductTable.querySelector('tbody');
      const rows = Array.from(tbody.rows);

      const selectedStudents = Array.isArray(this.selectedStudents) ? [...this.selectedStudents] : [];
      const newlySelected = [];

      if (!this.selectedStudentsByGroup) {
        this.selectedStudentsByGroup = {};
      }

      const groupKey = groupId ? `group-${groupId}` : null;
      const deliveryKey = deliveryId ? `delivery-${deliveryId}` : null;

      if (groupKey && !this.selectedStudentsByGroup[groupKey]) {
        this.selectedStudentsByGroup[groupKey] = [];
      }
      if (deliveryKey && !this.selectedStudentsByGroup[deliveryKey]) {
        this.selectedStudentsByGroup[deliveryKey] = [];
      }
  
      for (const row of rows) {
        try {
          const studentInfo = JSON.parse(row.getAttribute('data-student-info') || "{}");
          if (studentInfo) {
            const cellValue = studentInfo[prop];
            const studentId = studentInfo["id"].toString();
            const checkbox = row.querySelector('input[type="checkbox"]');
  
            if (items.includes(cellValue)) {
              if (checkbox) {
                checkbox.checked = true;

                if (groupKey) {
                  row.setAttribute("data-selected-by", groupKey);
                  if (!this.selectedStudentsByGroup[groupKey].includes(studentId)) {
                    this.selectedStudentsByGroup[groupKey].push(studentId);
                  }
                }
                if (deliveryKey) {
                  row.setAttribute("data-selected-by", deliveryKey);
                  if (!this.selectedStudentsByGroup[deliveryKey].includes(studentId)) {
                    this.selectedStudentsByGroup[deliveryKey].push(studentId);
                  }
                }

                if (!selectedStudents.includes(studentId)) {
                  selectedStudents.push(studentId);
                  newlySelected.push(cellValue);
                  checkbox.dispatchEvent(new Event("change", { bubbles: true }));
                }
              }
            }

            if (checkbox && checkbox.checked) {
              tbody.prepend(row);
            }
          }
        } catch (error) {
          console.error("Error parsing student info:", error);
        }
      }
  
      this.selectedStudents = selectedStudents;
      tbody.querySelectorAll('input[type="checkbox"]:checked').forEach((checkbox) => {
        checkbox.dispatchEvent(new Event("change", { bubbles: true }));
      });
      this.dispatchSelectedStudentsEvent();
    }
  }

  unselectSummaryTableRows() {
    if (!this.hasSummaryTableTarget) return;

    const aqueductTable = document.getElementById('SummaryTable');
    const tbody = aqueductTable.querySelector('tbody');
    const rows = Array.from(tbody.rows);
    let selectedStudents = [];
    let uncheckedCheckboxes = [];

    if (!this.pendingRemovalGroups) this.pendingRemovalGroups = [];
    if (!this.pendingRemovalDeliveries) this.pendingRemovalDeliveries = [];

    for (const row of rows) {
      try {
        const studentInfo = JSON.parse(row.getAttribute('data-student-info') || "{}");
        if (!studentInfo) continue;

        const studentId = studentInfo["id"].toString();
        const checkbox = row.querySelector('input[type="checkbox"]');

        const belongsToGroup = this.pendingRemovalGroups.some(groupId => {
          const groupKey = `group-${groupId}`;
          const studentIds = this.selectedStudentsByGroup[groupKey] || [];
          return studentIds.includes(studentId);
        });

        const belongsToDelivery = this.pendingRemovalDeliveries.some(deliveryId => {
          const deliveryKey = `delivery-${deliveryId}`;
          const studentIds = this.selectedStudentsByGroup[deliveryKey] || [];
          return studentIds.includes(studentId);
        });

        if ((belongsToGroup || belongsToDelivery) && checkbox) {
          checkbox.checked = false;
          row.removeAttribute("data-selected-by");
          uncheckedCheckboxes.push(checkbox);
        }

        if (checkbox && checkbox.checked) {
          selectedStudents.push(studentId);
        }
      } catch (error) {
        console.error("Error parsing student info:", error);
      }
    }

    this.selectedStudents = selectedStudents;

    uncheckedCheckboxes.forEach((checkbox) => {
      checkbox.dispatchEvent(new Event("change", { bubbles: true }));
    });

    this.dispatchSelectedStudentsEvent();

    this.pendingRemovalGroups = [];
    this.pendingRemovalDeliveries = [];
  }

  unselectStudentsByGroup(groupId = null, deliveryId = null) {
    if (!this.pendingRemovalGroups) this.pendingRemovalGroups = [];
    if (!this.pendingRemovalDeliveries) this.pendingRemovalDeliveries = [];

    if (groupId && !this.pendingRemovalGroups.includes(groupId)) {
      this.pendingRemovalGroups.push(groupId);
    }

    if (deliveryId && !this.pendingRemovalDeliveries.includes(deliveryId)) {
      this.pendingRemovalDeliveries.push(deliveryId);
    }
  }

  dispatchSelectedStudentsEvent() {
    this.element.dispatchEvent(new CustomEvent("students:selected", {
      detail: { selectedStudents: this.selectedStudents },
      bubbles: true
    }));
  }

  handleKeydown = (event) => {
    const modal = document.querySelector("[data-modal-target='modal']");
    
    if (modal && !modal.classList.contains("hidden")) {  
      if (event.key === "Enter") {
        this.fetchStudents(event);
      }
    }
  };
}
