import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["searchInput", "studentList", "checkbox", "viewResultsButton", "tableWrapper"];

  connect() {
    this.selectedStudents = [];
    this.updateTableWrapper();
    this.setupSearchInputBehavior();
    this.element.addEventListener("students:selected", (event) => {
      this.selectedStudents = [...new Set(this.selectedStudents)];
      this.updateViewResultsButton();
    });
  }

  filterStudents() {
    const searchTerm = this.searchInputTarget.value.toLowerCase();
    this.studentListTarget.querySelectorAll("tr").forEach((row) => {
      const email = row.children[1].textContent.toLowerCase();
      const firstName = row.children[2].textContent.toLowerCase();
      const lastName = row.children[3].textContent.toLowerCase();
      const matches = email.includes(searchTerm) || firstName.includes(searchTerm) || lastName.includes(searchTerm);
      row.style.display = matches ? "" : "none";
    });
    this.updateTableWrapper();
  }

  toggleSelection(event) {
    const checkbox = event.target;
    const studentId = checkbox.closest("tr").dataset.studentId;
    if (checkbox.checked) {
      if (!this.selectedStudents.includes(studentId)) {
        this.selectedStudents.push(studentId);
      }
    } else {
      this.selectedStudents = this.selectedStudents.filter(id => id !== studentId);
    }
    this.updateViewResultsButton();
    this.dispatchSelectedStudentsEvent();
  }

  updateViewResultsButton() {
    const button = this.viewResultsButtonTarget;
    if (this.selectedStudents.length === 0) {
      button.classList.add("disabled");
      button.style.pointerEvents = "none";
    } else {
      button.classList.remove("disabled");
      button.style.pointerEvents = "auto";
      this.updateViewResultsHref();
    }

    const rows = Array.from(this.studentListTarget.children);
    const checkedRows = rows.filter(row => row.querySelector('input[type="checkbox"]')?.checked);
    const uncheckedRows = rows.filter(row => !row.querySelector('input[type="checkbox"]')?.checked);

    [...checkedRows, ...uncheckedRows].forEach(row => this.studentListTarget.appendChild(row));
  }

  updateViewResultsHref() {
    const baseUrl = this.data.get("reportPath");
    const params = new URLSearchParams();
    this.selectedStudents.forEach((id) => {
      params.append("ids[]", id);
    });
    this.viewResultsButtonTarget.href = `${baseUrl}?${params.toString()}`;
  }

  viewResults(event) {
    if (this.selectedStudents.length === 0) {
      event.preventDefault();
    } else {
      analytics.track("open_calibrate_student_summary", {
        page: window.location.href,
        source: 'Assessment Dashboard',
        program: this.data.get("programValue"),
        userId: this.data.get("userIdValue"),
        userRole: this.data.get("userRoleValue"),
        programType: this.data.get("programTypeValue")
      });
    }
  }

  updateTableWrapper() {
    if (this.hasTableWrapperTarget) {
      const visibleRows = Array.from(this.studentListTarget.children).filter(row => row.style.display !== 'none');
  
      if (visibleRows.length > 25) {
        this.tableWrapperTarget.classList.add("scrollable");
      } else {
        this.tableWrapperTarget.classList.remove("scrollable");
      }
    }
  }  

  sort(event) {
    const column = event.target.dataset.sortColumn;
    const order = event.target.dataset.sortOrder;
    this.sortTable(column, order)
    this.updateSortIndicators(column, order);
  }

  sortTable(column, order) {
    const currentTable = event.target.closest('table');
    const tbody = currentTable.querySelector('[data-student-performance-target="studentList"]');
    
    if (!tbody) {
      console.warn("Could not find studentList target in the current table");
      return;
    }
    
    const rows = Array.from(tbody.querySelectorAll('tr'));
  
    rows.sort((a, b) => {
      const aCell = a.querySelector(`td[data-column="${column}"]`);
      const bCell = b.querySelector(`td[data-column="${column}"]`);
  
      if (!aCell || !bCell) {
        console.warn(`Column "${column}" not found in one of the rows.`);
        return 0;
      }
  
      const aValue = aCell.dataset.value.toLowerCase();
      const bValue = bCell.dataset.value.toLowerCase();
  
      if (aValue < bValue) {
        return order === 'asc' ? -1 : 1;
      }
      if (aValue > bValue) {
        return order === 'asc' ? 1 : -1;
      }
      return 0;
    });
  
    rows.forEach(row => tbody.appendChild(row));
  }

  updateSortIndicators(column, order) {
    const currentTable = event.target.closest('table');
    currentTable.querySelectorAll('.sort-icon').forEach(icon => {
      icon.classList.remove('active-asc', 'active-desc');
    });
  
    const activeIcon = currentTable.querySelector(`.sort-icon[data-sort-column="${column}"][data-sort-order="${order}"]`);
    if (activeIcon) {
      activeIcon.classList.add(order === 'asc' ? 'active-asc' : 'active-desc');
    }
  }

  toggleSort(event) {
    const column = event.currentTarget.dataset.sortColumn;
    const currentTable = event.currentTarget.closest('table');
    
    const currentAscIcon = currentTable.querySelector(`.sort-icon[data-sort-column="${column}"][data-sort-order="asc"]`);
    const currentDescIcon = currentTable.querySelector(`.sort-icon[data-sort-column="${column}"][data-sort-order="desc"]`);
    
    if (!currentAscIcon || !currentDescIcon) {
      console.warn("Sort icons not found for column:", column);
      return;
    }
    
    const isAscActive = currentAscIcon.classList.contains('active-asc');
    const isDescActive = currentDescIcon.classList.contains('active-desc');
    
    let newOrder;
    if (isAscActive) {
      newOrder = 'desc';
    } else if (isDescActive) {
      newOrder = 'asc';
    } else {
      newOrder = 'asc';
    }
    
    this.sortTable(column, newOrder);
    this.updateSortIndicators(column, newOrder);
  }

  setupSearchInputBehavior() {
    const searchInput = this.searchInputTarget;
    
    this.updateSearchIconVisibility(searchInput);
    
    searchInput.addEventListener('focus', () => {
      searchInput.classList.add('no-icon');
    });
    
    searchInput.addEventListener('blur', () => {
      this.updateSearchIconVisibility(searchInput);
    });
    
    searchInput.addEventListener('input', () => {
      this.updateSearchIconVisibility(searchInput);
    });
  }
  
  updateSearchIconVisibility(input) {
    if (input.value.trim().length > 0) {
      input.classList.add('no-icon');
    } else if (!input.matches(':focus')) {
      input.classList.remove('no-icon');
    }
  }

  dispatchSelectedStudentsEvent() {
    this.element.dispatchEvent(new CustomEvent("students:selected", {
      detail: { selectedStudents: this.selectedStudents },
      bubbles: true
    }));
  }  
}
