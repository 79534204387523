import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["card", "nextBtn", "prevBtn", "toHonorCode", "todoDropdown"];

  connect() {
    this.currentCardIndex = 0;

    if (this.cardTargets.length > 0) {
      this.cardTargets[this.currentCardIndex].classList.add('active');
    }
  }

  goToNextCard() {
    if (this.currentCardIndex < this.cardTargets.length - 1) {
      this.cardTargets[this.currentCardIndex].classList.remove('active');
      this.currentCardIndex++;
      this.cardTargets[this.currentCardIndex].classList.add('active');
    }
  }

  goToPreviousCard() {
    if (this.currentCardIndex > 0) {
      this.cardTargets[this.currentCardIndex].classList.remove('active');
      this.currentCardIndex--;
      this.cardTargets[this.currentCardIndex].classList.add('active');
    }
  }

  skipAssessment() {
    this.currentCardIndex = this.cardTargets.length - 1; // Jump to last card
    this.cardTargets.forEach(card => card.classList.remove('active')); // Hide all cards
    this.cardTargets[this.currentCardIndex].classList.add('active');
  }

  toggleDropdown(event) {
    let dropdownContainer = this.todoDropdownTarget;
    dropdownContainer.classList.toggle("hidden", event.target.value !== "true");
    document.getElementById("autoAddTodosField").value = event.target.value === "true";
    document.getElementById('toHonorCode').disabled = event.target.value === "true";

  }

  honorSubmit() {
    document.getElementById('honorCodeCheckbox').addEventListener('change', function () {
      document.getElementById('nextButton').disabled = !this.checked;
    });
  }

  toggleMultiSelect() {
    // Toggle visibility of the multi-select dropdown
    document.getElementById("todoListDropdown").classList.toggle("hidden");
  }

  updateSelectedLists() {
    const selectedIds = Array.from(document.querySelectorAll("input[name='todo_list_ids[]']:checked")).map(checkbox => checkbox.value);
    const newListName = document.getElementById("newTodoListName").value.trim();

    // Update hidden fields
    document.getElementById("todoListIdsField").value = selectedIds.join(',');
    document.getElementById("newTodoListField").value = newListName;

    // Enable submit button if at least one list is selected or a new list is created
    document.getElementById("toHonorCode").disabled = selectedIds.length === 0 && newListName === "";
  }

  toggleNewListInput(event) {
    document.getElementById("newTodoListName").classList.toggle("hidden", !event.target.checked);
    if (!event.target.checked) {
      document.getElementById("newTodoListName").value = "";
    }
    this.updateSelectedLists();
  }

  trackAutoAddToDoSelection() {
    const autoAddTodos = document.getElementById("autoAddTodosField")?.value;
    const rosteringId = document.getElementById("rosteringId")?.value;

    analytics.track("calibrate_auto_add_to_do_selection", {
      page: window.location.href,
      source: "Calibrate",
      enabled_auto_todos: autoAddTodos,
      rostering_id: rosteringId,
    });
  }
}
